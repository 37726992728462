#feedbackForm {
  input[type=text], input[type=email], textarea {
    &:required {
      border-color: black;
    }
  }

  .form-buttons {
    .btn {
      display: block;
    }
  }

  @include media-breakpoint-up(md) {
    .form-buttons {
      .btn {
        display: inline-block;
      }

      .btn-primary {
        width: 350px;
      }

      .btn-secondary {
        width: 200px;
      }
    }
  }
}
